import {
	Template1,
	Template2,
	Template3,
	Template4,
	Template5
} from "../../templates";

export const AssessmentContent = ({ content, domain }) => {
	const templateType = {
		TEMPLATE_1: 1,
		TEMPLATE_2: 2,
		TEMPLATE_3: 3,
		TEMPLATE_4: 4,
		TEMPLATE_5: 5,
		TEMPLATE_6: 6,
		TEMPLATE_7: 7
	};

	switch (content?.template) {
		case templateType.TEMPLATE_1:
			// Listening
			return <Template1 intro={content} domain={domain} />;
		case templateType.TEMPLATE_2:
			// Reading, Writing, Speaking
			return <Template2 intro={content} domain={domain} />;
		case templateType.TEMPLATE_3:
			// Writing
			return <Template3 question={content} domain={domain} />;
		case templateType.TEMPLATE_4:
			// Speaking
			return <Template4 question={content} domain={domain} />;
		case templateType.TEMPLATE_5:
			// Reading
			return <Template5 question={content} />;
		case templateType.TEMPLATE_6:
			// Listening
			return <>placeholder 6</>;
		case templateType.TEMPLATE_7:
			// Listening
			return <>placeholder 7</>;
		default:
			return <>placeholder for error</>;
	}
};
