import React from "react";
import { Box, Typography } from "@ellevation/backpack";
import { usePoc } from "../../PocContextProvider";
import { TextToSpeech } from "../../components/TextToSpeech";

export const Template2 = ({ intro, domain }) => {
	const { currentContentIndex } = usePoc();
	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				flexDirection: "column",
				mt: "20px"
			}}>
			<Typography
				sx={{
					backgroundColor: "#F9F9F9",
					width: "788px",
					mt: 3,
					py: 2,
					px: 1,
					fontSize: "24px",
					textAlign: "center"
				}}
				variant="h4">
				{intro.title}
			</Typography>
			{intro.textA && (
				<Box sx={{ mt: 3, mb: 2, width: "805px" }}>
					<Typography sx={{ fontSize: "21px" }}>
						{domain !== "Reading" && (
							<TextToSpeech
								text={intro.textA}
								id={currentContentIndex}
								domain={domain}
							/>
						)}
						{intro.textA}
					</Typography>
				</Box>
			)}
			{intro.stimulus && (
				<Box sx={{ width: 805 }}>
					<img
						style={{
							height: "auto",
							width: "100%",
							marginTop: 2,
							borderRadius: "8px",
							paddingx: "-16px"
						}}
						src={intro.stimulus}
						alt="stimulus image"
					/>
				</Box>
			)}
		</Box>
	);
};
