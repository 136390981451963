import { useState, useEffect } from "react";
import { Box, Button, Toast } from "@ellevation/backpack";
import {
	AssessmentContent,
	ConfirmationDialog,
	Instructions,
	Outro
} from "../../components";
import { pocAssessmentFetchHelper } from "lib/api/pocAssessmentAPI";
import { usePoc } from "../../PocContextProvider";
import * as Sentry from "@sentry/browser";
import {
	faChevronRight,
	faChevronLeft
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";

export const AssessmentContainer = ({ assessmentId, data }) => {
	const [intro, setIntro] = useState(true);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [toastToggle, setToastToggle] = useState(false);

	const { content, user } = data;
	const { domain, items, lastCompleted } = content;

	const {
		currentContentIndex,
		responses,
		indexOffset,
		setIndexOffset,
		setResponses,
		updateCurrentContentIndex,
		registerPlayingAudio
	} = usePoc();

	const handleInstructions = () => {
		setIntro(intro => !intro);
	};

	const itemType = {
		MULTIPLE_CHOICE: "multiple_choice",
		FILL_IN: "fill_in",
		WRITTEN: "written",
		SPOKEN: "spoken"
	};

	useEffect(() => {
		setResponses(
			items.flatMap((item, index) => {
				if (item.content?.intro && item.content?.question) {
					return [
						{ id: item.id, type: item.type, intro: item.content.intro },
						{
							id: item.id,
							type: item.type,
							question: { ...item.content.question, questionNumber: index + 1 }
						}
					];
				}
				return [
					{
						id: item.id,
						type: item.type,
						question: { ...item.content.question, questionNumber: index + 1 }
					}
				];
			})
		);
	}, [items]);

	useEffect(() => {
		if (lastCompleted !== null) {
			setIntro(false);
			let howManyIntrosToSkip = 0;
			for (let i = 0; i <= lastCompleted; i++) {
				/* istanbul ignore next */
				if (items[i]?.content?.intro) {
					howManyIntrosToSkip++;
				}
			}
			setIndexOffset(howManyIntrosToSkip);
			updateCurrentContentIndex(lastCompleted + 1 + howManyIntrosToSkip);
		}
	}, [lastCompleted]);

	// Multiple choice and Written response submittal

	const handleInputSumbit = async () => {
		const currentContent = responses[currentContentIndex];
		const response = await pocAssessmentFetchHelper(
			`response/${responses[currentContentIndex].id}`,
			"POST",
			{
				assessment_id: assessmentId,
				response: {
					answer: currentContent.question.response
				}
			}
		);
		/* istanbul ignore next */
		if (response.ok) {
			const result = await response.json();
			return result;
		}
	};

	// Spoken response uploading and submittal

	const uploadAudioToS3 = async (url, fields) => {
		let formData = new FormData();

		const file = new File(
			[responses[currentContentIndex].question?.response?.blob],
			"filename",
			{ type: "audio/mpeg" }
		);

		formData.append("key", fields["key"]);
		formData.append("AWSAccessKeyId", fields["AWSAccessKeyId"]);
		formData.append("policy", fields["policy"]);
		formData.append("signature", fields["signature"]);
		formData.append("x-amz-security-token", fields["x-amz-security-token"]);
		formData.append("content-type", "audio/mpeg");
		formData.append("acl", "private");
		formData.append("file", file);

		/* istanbul ignore next */
		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData
			});
			if (response.ok) {
				const postResponse = await pocAssessmentFetchHelper(
					`response/${responses[currentContentIndex].id}`,
					"POST",
					{
						assessment_id: assessmentId,
						response: { audio_url: url + fields["key"] }
					}
				);
				if (postResponse.ok) {
					const result = await postResponse.json();
					return result;
				}
			}
		} catch (err) {
			Sentry.captureException(err);
		}
	};

	const handleSpokenSubmit = async () => {
		const response = await pocAssessmentFetchHelper(
			`response/${responses[currentContentIndex].id}/presigned-post`,
			"GET"
		);
		/* istanbul ignore next */
		if (response.ok) {
			const result = await response.json();
			uploadAudioToS3(result?.url, result?.fields);
		}
	};

	const handleNext = async () => {
		const currentContent = responses[currentContentIndex];
		const currentPositionIsIntro = currentContent?.intro;
		const currentPotisionIsQuestion = currentContent?.question;

		if (currentPositionIsIntro) {
			updateCurrentContentIndex(currentContentIndex + 1);
		}
		if (currentPotisionIsQuestion) {
			handleContentSubmit(responses[currentContentIndex]);
		}
		await registerPlayingAudio("tts", null);
	};

	const handleContentSubmit = content => {
		const questionHasInput = content.question?.response;
		const atTheEndofContent = currentContentIndex === responses.length - 1;
		if (questionHasInput) {
			handleContentType(content);
			if (atTheEndofContent) {
				setDialogOpen(true);
			} else {
				updateCurrentContentIndex(currentContentIndex + 1);
			}
		} else {
			setToastToggle(true);
		}
	};

	const handleContentType = content => {
		switch (content.type) {
			case itemType.MULTIPLE_CHOICE:
			case itemType.WRITTEN:
				return handleInputSumbit();
			case itemType.SPOKEN:
				return handleSpokenSubmit();
		}
	};

	const handlePrev = () => {
		updateCurrentContentIndex(currentContentIndex - 1);
	};

	return (
		<Box>
			{intro && (
				<Instructions
					name={`${user.firstname}`}
					domain={domain}
					toggleIntro={handleInstructions}
					questionCount={items.length}
				/>
			)}
			{!intro && currentContentIndex <= responses.length - 1 && (
				<Box>
					<Box sx={{ pb: "69px" }}>
						<AssessmentContent
							content={
								responses[currentContentIndex].intro
									? responses[currentContentIndex].intro
									: responses[currentContentIndex].question
							}
							domain={domain}
						/>
					</Box>

					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							gap: "12px",
							py: "10px",
							width: "100%",
							borderTop: 1,
							borderColor: "#E7E7E7",
							position: "fixed",
							bottom: 0,
							backgroundColor: "white"
						}}>
						<Toast
							sx={{ mb: 8 }}
							anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
							open={toastToggle}
							setOpen={setToastToggle}
							severity="warning">
							<Typography sx={{ fontSize: "21px", color: "initial" }}>
								Answer the question to continue.
							</Typography>
						</Toast>

						<Button
							color="secondary"
							disabled={
								currentContentIndex === 0 ||
								(lastCompleted !== null &&
									lastCompleted + 1 + indexOffset === currentContentIndex)
							}
							onClick={handlePrev}
							sx={{
								minWidth: "auto",
								width: 48,
								height: 48,
								borderRadius: "50%"
							}}
							aria-label="back">
							<FontAwesomeIcon
								icon={faChevronLeft}
								style={{ width: 16, height: 16 }}
							/>
						</Button>
						<Button
							color="primary"
							sx={{
								minWidth: "auto",
								width: 48,
								height: 48,
								borderRadius: "50%"
							}}
							onClick={handleNext}
							aria-label="next">
							<FontAwesomeIcon
								icon={faChevronRight}
								style={{ width: 16, height: 16 }}
							/>
						</Button>
					</Box>
				</Box>
			)}
			{!intro && currentContentIndex === responses.length && (
				<Outro domain={domain} questionCount={items.length} />
			)}
			<ConfirmationDialog
				dialogOpen={dialogOpen}
				setDialogOpen={setDialogOpen}
				onSubmit={() => updateCurrentContentIndex(currentContentIndex + 1)}
				domain={domain}
			/>
		</Box>
	);
};
