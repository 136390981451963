import { useState } from "react";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	StandardDialogActions,
	Typography
} from "@ellevation/backpack";

export const DeleteDialog = ({ dialogOpen, setDialogOpen, onDelete }) => {
	const onDeleteHandler = async () => {
		setDialogOpen(false);
		onDelete();
	};
	const onBackHandler = async () => {
		setDialogOpen(false);
	};

	return (
		<Dialog open={dialogOpen} onClose={onBackHandler}>
			<DialogTitle data-testid="dialogTitle">Delete Answer</DialogTitle>
			<DialogContent>
				<Typography sx={{ fontSize: "21px" }}>
					Are you sure you want to delete your answer? You will need to record a
					new answer to continue.
				</Typography>
			</DialogContent>
			<DialogActions>
				<StandardDialogActions
					destructive={true}
					primaryText={"Delete"}
					secondaryText={"Back"}
					primaryClickHandler={onDeleteHandler}
					secondaryClickHandler={onBackHandler}
				/>
			</DialogActions>
		</Dialog>
	);
};
