import React from "react";
import { Box, FormControl, Typography, TextInput } from "@ellevation/backpack";
import { usePoc } from "../../PocContextProvider";
import { TextToSpeech } from "../../components/TextToSpeech";

export const Template3 = ({ question, domain }) => {
	const { updateResponses, currentContentIndex, responses } = usePoc();

	const handleChange = event => {
		updateResponses(currentContentIndex, event.target.value);
	};

	return (
		<Box sx={{ mt: 4, width: "1131px", mx: "auto" }}>
			<Typography
				sx={{
					backgroundColor: "#F9F9F9",
					mt: 2,
					mx: 2,
					py: 2,
					px: 1,
					fontSize: "24px",
					textAlign: "center"
				}}
				variant="h4">
				{question.title}
			</Typography>
			<Box sx={{ display: "flex", flexDirection: "row" }}>
				<Box sx={{ ml: 2, mt: 3, mr: 12.5, width: "495px" }}>
					{question.textA && (
						<Typography sx={{ mt: 3, fontSize: "21px" }}>
							<TextToSpeech
								text={question.textA}
								id={currentContentIndex}
								domain={domain}
							/>
							{question.textA}
						</Typography>
					)}
					{question.stimulus && (
						<img
							style={{
								maxWidth: "100%",
								marginTop: "16px",
								borderRadius: "8px"
							}}
							src={question.stimulus}
							alt="stimulus image"
						/>
					)}
				</Box>
				<Box sx={{ mt: 3, width: "45%" }}>
					<FormControl component="fieldset" sx={{ width: "100%" }}>
						<Box sx={{ display: "flex", flexDirection: "row" }}>
							<Box
								sx={{ mr: 2, mt: "6px", fontSize: "21px", fontWeight: "600" }}>
								{question.questionNumber}
							</Box>
							<Typography sx={{ pr: 1, fontSize: "21px" }}>
								<TextToSpeech
									text={question.textB}
									id={currentContentIndex}
									domain={domain}
								/>
								{question.textB}
							</Typography>
						</Box>
						<Box sx={{ width: "100%" }}>
							<TextInput
								sx={{
									mt: 3,
									ml: 3,
									width: "93%",
									"& .MuiInputBase-root": {
										fontSize: "1.25rem",
										lineHeight: "150%"
									}
								}}
								value={
									responses[currentContentIndex]?.question?.response
										? responses[currentContentIndex]?.question?.response
										: ""
								}
								placeholder="Type your answer"
								onChange={handleChange}
								data-testid="writtenField"
								multiline
								minRows={6}
							/>
						</Box>
					</FormControl>
				</Box>
			</Box>
		</Box>
	);
};
