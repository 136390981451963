import React, { useState, useEffect, useRef } from "react";
import { Box, FormControl, Typography, Button } from "@ellevation/backpack";
import { usePoc } from "../../PocContextProvider";
import { TextToSpeech } from "../../components/TextToSpeech";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faMicrophone,
	faStop,
	faTrashAlt
} from "@fortawesome/pro-solid-svg-icons";
import { DeleteDialog } from "pages/POCAssessmentPage/components/DeleteDialog";
import { ListeningBar } from "pages/POCAssessmentPage/components";
import { postAssessmentEvent } from "lib/api/pocAssessmentAPI";


export const Template4 = ({ question, domain }) => {
	const { updateResponses, currentContentIndex, responses } = usePoc();
	const [time, setTime] = useState(false);
	const [isRecording, setIsRecording] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const mediaRecorderRef = useRef(null);
	const chunksRef = useRef([]);
	const durationRef = useRef(0);

	useEffect(() => {
		let intervalId;
		if (isRecording) {
			/* istanbul ignore next */
			intervalId = setInterval(() => {
				setTime(prevTime => {
					const newTime = prevTime + 1;
					durationRef.current = newTime; // Update the durationRef with the current time
					return newTime;
				});
			}, 1000); // Increment every second
		} else {
			clearInterval(intervalId); // Clear interval when recording stops
		}
		return () => clearInterval(intervalId); // Cleanup the interval on unmount or state change
	}, [isRecording]);

	const startRecording = async () => {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({
				audio: true
			});
			mediaRecorderRef.current = new MediaRecorder(stream);
			mediaRecorderRef.current.ondataavailable = handleDataAvailable;
			mediaRecorderRef.current.start();
			setIsRecording(true);
		} catch (err) {
			/* istanbul ignore next */
			console.error("Error accessing microphone:", err);
		}
	};

	const duration = durationRef.current;
	const stopRecording = () => {
		/* istanbul ignore next */
		if (
			mediaRecorderRef.current &&
			mediaRecorderRef.current.state !== "inactive"
		) {
			mediaRecorderRef.current.stop();
			handleStop();
			setTime(0);
			setIsRecording(false);
		}
	};

	/* istanbul ignore next */
	const handleDataAvailable = event => {
		if (event.data.size > 0) {
			chunksRef.current.push(event.data);
			const blob = new Blob(chunksRef.current, { type: "audio/mpeg" });
			updateResponses(currentContentIndex, {
				blob: blob,
				audioUrl: URL.createObjectURL(blob),
				duration: durationRef.current
			});
		}
	};

	/* istanbul ignore next */
	const handleStop = () => {
		const blob = new Blob(chunksRef.current, { type: "audio/mpeg" });
		updateResponses(currentContentIndex, {
			blob: blob,
			url: URL.createObjectURL(blob)
		});
		chunksRef.current = [];
		mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
	};

	/* istanbul ignore next */
	const handleDeleteClose = async () => {
		updateResponses(currentContentIndex, null);
		await postAssessmentEvent(
			"rerecord_spoken_response",
			currentContentIndex,
			domain
		);
	};

	return (
		<Box sx={{ mt: 4, width: "1131px", mx: "auto" }}>
			<Typography
				sx={{
					backgroundColor: "#F9F9F9",
					mt: 2,
					mx: 2,
					py: 2,
					px: 1,
					fontSize: "24px",
					textAlign: "center"
				}}
				variant="h4">
				{question.title}
			</Typography>
			<Box sx={{ display: "flex", flexDirection: "row" }}>
				<Box sx={{ ml: 2, mt: 3, mr: 12.5, width: "495px" }}>
					{question.textA && (
						<Typography sx={{ mt: 3, fontSize: "21px" }}>
							<TextToSpeech
								text={question.textA}
								id={currentContentIndex}
								domain={domain}
							/>
							{question.textA}
						</Typography>
					)}
					{question.stimulus && (
						<img
							style={{
								maxWidth: "100%",
								marginTop: "16px",
								borderRadius: "8px"
							}}
							src={question.stimulus}
							alt="stimulus image"
						/>
					)}
				</Box>
				<Box sx={{ mt: 3, width: "45%" }}>
					<FormControl component="fieldset" sx={{ width: "100%" }}>
						<Box sx={{ display: "flex", flexDirection: "row" }}>
							<Box
								sx={{ mr: 2, mt: "6px", fontSize: "21px", fontWeight: "600" }}>
								{question.questionNumber}
							</Box>
							<Typography sx={{ pr: 1, fontSize: "21px" }}>
								<TextToSpeech
									text={question.textB}
									id={currentContentIndex}
									domain={domain}
								/>
								{question.textB}
							</Typography>
						</Box>
						<Box
							sx={{
								width: "100%",
								height: "48px",
								mt: 2,
								ml: 3,
								display: "flex",
								alignItems: "center"
							}}>
							{!responses[currentContentIndex].question.response?.audioUrl && (
								<Button
									color={isRecording ? "destructive" : "primary"}
									sx={{
										minWidth: "auto",
										width: 64,
										height: 64,
										borderRadius: "50%",
										mr: 2
									}}
									onClick={isRecording ? stopRecording : startRecording}>
									<FontAwesomeIcon
										icon={isRecording ? faStop : faMicrophone}
										style={{ width: 24, height: 24 }}
									/>
								</Button>
							)}
							{isRecording && (
								<Typography variant="h4">Recording...</Typography>
							)}
							{responses[currentContentIndex].question.response?.audioUrl &&
								!isRecording && (
									<Box sx={{ display: "flex" }}>
										<Box sx={{ width: "450px", mr: 1 }}>
											<ListeningBar
												audioUrl={
													responses[currentContentIndex].question.response
														?.audioUrl
												}
												id={currentContentIndex}
												domain={domain}
												recordedAudioDuration={
													/* istanbul ignore next */
													responses[currentContentIndex].question.response
														?.duration
														? responses[currentContentIndex].question.response
																?.duration
														: durationRef.current
												}></ListeningBar>
										</Box>
										<FontAwesomeIcon
											icon={faTrashAlt}
											style={{
												color: "gray",
												cursor: "pointer",
												marginTop: "30px"
											}}
											onClick={() =>
												setDeleteDialogOpen(true)
											}></FontAwesomeIcon>
									</Box>
								)}
						</Box>
					</FormControl>
				</Box>
			</Box>
			{deleteDialogOpen && (
				<DeleteDialog
					dialogOpen={true}
					setDialogOpen={setDeleteDialogOpen}
					onDelete={handleDeleteClose}></DeleteDialog>
			)}
		</Box>
	);
};
