import { Box, Button, Typography } from "@ellevation/backpack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronRight,
	faPlay,
	faTrashAlt,
	faMicrophone,
	faStop
} from "@fortawesome/pro-solid-svg-icons";
import reading from "img/domain/Reading.svg";
import writing from "img/domain/Writing.svg";
import listening from "img/domain/Listening.svg";
import speaking from "img/domain/Speaking.svg";
import { TextToSpeech } from "../TextToSpeech";
import { usePoc } from "../../PocContextProvider";
import { postAssessmentEvent } from "lib/api/pocAssessmentAPI";

export const Instructions = ({ name, domain, toggleIntro, questionCount }) => {
	const { registerPlayingAudio } = usePoc();

	const getDomainImage = domain => {
		switch (domain) {
			case "Reading":
				return reading;
			case "Writing":
				return writing;
			case "Listening":
				return listening;
			case "Speaking":
				return speaking;
		}
	};

	const getInstructions = domain => {
		switch (domain) {
			case "Reading":
				return (
					<>
						<TextToSpeech
							text="Read the passages, then answer questions about each passage. 
						Be sure to answer all of the questions."
							id={0}
							domain={domain}
						/>
						Read the passages, then answer questions about each passage. Be sure
						to answer all of the questions. 
					</>
				);
			case "Writing":
				return (
					<>
						<TextToSpeech
							text="Write your answer to each question. Be sure to answer all of the questions."
							id={0}
							domain={domain}
						/>
						Write your answer to each question. Be sure to answer all of the
						questions.
					</>
				);
			case "Listening":
				return (
					<>
						<TextToSpeech
							text="You will use your listening skills to answer questions. Select the
						play button to listen. You must complete all the questions. You can change your
						answers before submitting."
							id={0}
							domain={domain}
						/>
						You will use your listening skills to answer questions. Select the
						play button{" "}
						<FontAwesomeIcon
							icon={faPlay}
							style={{
								border: "0.5px solid",
								borderRadius: "50%",
								height: "12px",
								width: "12px",
								padding: "6px",
								verticalAlign: "middle"
							}}
						/>{" "}
						to listen. You must complete all the questions. You can change your
						answers before submitting.
					</>
				);
			case "Speaking":
				return (
					<>
						<TextToSpeech
							text="Say your answer to each question. Be sure to answer all of the questions. Select Microphone to record your answer. Select Stop at the end of your answer. Select Play to listen to your answer. Select Trash if you want to record a different answer."
							id={0}
							domain={domain}
						/>
						Say your answer to each question. Be sure to answer all of the
						questions.
						<Box sx={{ pl: "50px", pt: "20px" }}>
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<FontAwesomeIcon
									icon={faMicrophone}
									style={{
										border: "0.5px solid",
										borderRadius: "50%",
										height: "16px",
										width: "16px",
										padding: "4px",
										marginRight: "9px"
									}}
								/>
								<Box>
									Select{" "}
									<Typography
										component="span"
										sx={{ fontSize: "21px", fontFamily: "bold" }}>
										Microphone
									</Typography>{" "}
									to record your answer.
								</Box>
							</Box>
							<Box sx={{ pt: "16px", display: "flex", alignItems: "center" }}>
								<FontAwesomeIcon
									icon={faStop}
									style={{
										border: "0.5px solid",
										borderRadius: "50%",
										height: "14px",
										width: "14px",
										padding: "5px",
										marginRight: "9px"
									}}
								/>
								<Box>
									Select{" "}
									<Typography
										component="span"
										sx={{ fontSize: "21px", fontFamily: "bold" }}>
										Stop
									</Typography>{" "}
									at the end of your answer.
								</Box>
							</Box>
							<Box sx={{ pt: "16px", display: "flex", alignItems: "center" }}>
								<FontAwesomeIcon
									icon={faPlay}
									style={{
										border: "0.5px solid",
										borderRadius: "50%",
										height: "12px",
										width: "12px",
										padding: "6px",
										marginRight: "9px"
									}}
								/>
								<Box>
									Select{" "}
									<Typography
										component="span"
										sx={{ fontSize: "21px", fontFamily: "bold" }}>
										Play
									</Typography>{" "}
									to listen to your answer.
								</Box>
							</Box>
							<Box sx={{ pt: "16px", display: "flex", alignItems: "center" }}>
								<FontAwesomeIcon
									icon={faTrashAlt}
									style={{
										border: "0.5px solid",
										borderRadius: "50%",
										height: "14px",
										width: "14px",
										padding: "5px",
										marginRight: "9px"
									}}
								/>
								<Box>
									Select{" "}
									<Typography
										component="span"
										sx={{ fontSize: "21px", fontFamily: "bold" }}>
										Trash
									</Typography>{" "}
									if you want to record a different answer.
								</Box>
							</Box>
						</Box>
					</>
				);
		}
	};

	return (
		<Box
			sx={{
				backgroundColor: "#F9F9F9",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				height: "calc(100vh - 57px)"
			}}>
			<Box
				sx={{
					backgroundColor: "white",
					mt: "56px",
					p: "24px 40px",
					width: "50%",
					maxWidth: "711px"
				}}>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						pb: "20px",
						borderBottom: "1px solid #CCCCCC"
					}}>
					<img src={getDomainImage(domain)} />
					<Box sx={{ pl: "16px" }}>
						<Typography variant="h1">{domain}</Typography>
						<Typography sx={{ fontSize: "21px", color: "#666666" }}>
							{questionCount} questions
						</Typography>
					</Box>
				</Box>
				<Typography variant="h3" sx={{ pt: "28px" }}>
					Hi {name}!
				</Typography>
				<Typography sx={{ pt: "24px", pb: "32px", fontSize: "21px" }}>
					{getInstructions(domain)}
				</Typography>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center"
					}}>
					<Button
						sx={{ px: 6, py: 3, fontSize: 21 }}
						onClick={async () => {
							await registerPlayingAudio("tts", null);
							await postAssessmentEvent("domain_started", 0, domain);
							toggleIntro();
						}}>
						Start
						<FontAwesomeIcon
							icon={faChevronRight}
							style={{ paddingLeft: "8px", width: 16, height: 16 }}
						/>
					</Button>
				</Box>
			</Box>
		</Box>
	);
};
